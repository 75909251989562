import { Button, Grid, Paper, TextInput } from "@mantine/core";
import { useFormik } from "formik";
//import { UploadImage } from "./UploadImage";

interface Props {
  skuPrefix: string;
  description: string;
  onSubmit: (values: { skuPrefix: string; description: string }) => void;
}
const HoseDetailForm = ({ skuPrefix, description, onSubmit }: Props) => {
  const form = useFormik({
    initialValues: {
      skuPrefix: skuPrefix,
      description: description,
    },
    onSubmit: (values) => onSubmit(values),
  });

  return (
    <Grid mt="lg">
      <Grid.Col xs={12}>
        <Paper component="form" onSubmit={form.handleSubmit} shadow="xs" p={25}>
          <TextInput
            required
            id="description"
            name="description"
            label="Description"
            onChange={form.handleChange}
            value={form.values.description}
          />
          <TextInput
            required
            mt="lg"
            id="skuPrefix"
            name="skuPrefix"
            label="SKU Prefix"
            onChange={form.handleChange}
            value={form.values.skuPrefix}
          />
          <Button type="submit" mt="lg">
            Update Options
          </Button>
        </Paper>
      </Grid.Col>
    </Grid>
  );
};

export default HoseDetailForm;
