import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface AuthState {
  shipmasterToken: string;
  stockmasterToken: string;
  profile: Profile;
}

export interface Profile {
  name?: string;
  email?: string;
}

const initialState: AuthState = {
  shipmasterToken: "",
  stockmasterToken: "",
  profile: {
    name: "",
    email: "",
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateProfile(state, action: PayloadAction<Profile>) {
      state.profile.name = action.payload?.name;
      state.profile.email = action.payload?.email;
    },
    updateShipmasterToken(state, action: PayloadAction<string>) {
      state.shipmasterToken = action.payload;
    },
    updateStockmasterToken(state, action: PayloadAction<string>) {
      state.stockmasterToken = action.payload;
    },
  },
});

export const { updateProfile, updateShipmasterToken, updateStockmasterToken } =
  authSlice.actions;
export default authSlice.reducer;
