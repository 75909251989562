import { useMsal } from "@azure/msal-react";
import { Anchor, Avatar, Box, Button, Group, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hook";

const User = () => {
  const auth = useAppSelector((state) => state.auth);
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  };

  function getInitials(name: string) {
    var names = name.split(" ");
    var initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }
  return (
    <Box sx={(theme) => ({ borderTop: `1px solid ${theme.colors.gray[2]}`, paddingTop: theme.spacing.sm, padding: theme.spacing.xs })}>
      <Group align="top">
        <Avatar color="brand" radius="xl">
          {getInitials(auth.profile.name || "?")}
        </Avatar>
        <Box>
          <Anchor component={Link} to="/">
            <Text size="sm" weight={600}>
              {auth.profile.name}
            </Text>
            <Text size="xs" color="dimmed">
              {auth.profile.email}
            </Text>
          </Anchor>
          <Button variant="outline" compact mt={10} onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </Group>
    </Box>
  );
};

export default User;
