import { createAsyncThunk } from "@reduxjs/toolkit";
import { listingApi } from "../../../apiConfig";
import { HoseGroup } from "../types";

export const getHoseGroups = createAsyncThunk<HoseGroup[]>("listingApi/getHoseGroups", async () => {
  const response = await listingApi.get(`hosegroup`);
  return response.data;
});

export const getHoseGroup = createAsyncThunk<HoseGroup, string>("listingApi/getHoseGroup", async (id: string) => {
  const response = await listingApi.get(`hosegroup/${id}`);
  return response.data;
});

export const createHoseGroup = createAsyncThunk<HoseGroup, HoseGroup>("listingApi/createHoseGroup", async (hoseGroup: HoseGroup) => {
  const response = await listingApi.post(`hosegroup`, hoseGroup);
  return response.data;
});

export const deleteHoseGroup = createAsyncThunk<HoseGroup, string>("listingApi/deleteHoseGroup", async (id: string) => {
  const response = await listingApi.delete(`hosegroup/${id}`);
  return response.data;
});

export const updateHoseGroup = createAsyncThunk<HoseGroup, HoseGroup>("listingApi/updateHoseGroup", async (hoseGroup: HoseGroup) => {
  const response = await listingApi.put(`hosegroup/${hoseGroup.id}`, hoseGroup);
  return response.data;
});

export const uploadHoseImage = createAsyncThunk<void, FormData>("listingApi/uploadHoseImage", async (body: FormData) => {
  const response = await listingApi.post(`hosegroup/upload`, body);
  return response.data;
});
