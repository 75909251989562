import { useState } from "react";
import { LengthOption } from "../types";
import { Box, UnstyledButton, Text, Group, Popover, NumberInput, Button } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { Calculator } from "tabler-icons-react";
import { useAppDispatch } from "../../../app/hook";
import { updateLengths } from "../draftListing-slice";

interface Props {
  lengths: LengthOption[];
}
const LengthList = ({ lengths }: Props) => {
  const dispatch = useAppDispatch();
  const [selected, setSelected] = useState<LengthOption | null>(null);

  const form = useForm({
    initialValues: {
      costMultiplier: 2,
    },
  });

  function handleSelected(option: LengthOption) {
    // update form with selected diameter
    form.setValues({
      costMultiplier: option.costMultiplier,
    });

    setSelected(option);
  }

  function updateLength(updatedLength: { costMultiplier: number }) {
    if (!selected) return;

    // Check lengths for existing lengths
    const updated = lengths.map((option) => {
      if (option.length === selected.length) {
        return { ...option, costMultiplier: updatedLength.costMultiplier };
      }

      return option;
    });

    dispatch(updateLengths(updated));
    setSelected(null);
  }

  function removeLength() {
    if (!selected) return;

    const updated = lengths.filter((option) => option.length !== selected.length);

    dispatch(updateLengths(updated));
    setSelected(null);
  }

  return (
    <Box>
      <Text weight={600} size="sm">
        Lengths (metres)
      </Text>
      <Group spacing="xs" mt="md">
        {lengths.length === 0 && <Text size="sm">No options</Text>}
        {lengths.map((option, index) => (
          <Popover
            key={index}
            opened={selected === option}
            onClose={() => setSelected(null)}
            position="bottom"
            placement="start"
            shadow="lg"
            spacing={0}
            width={320}
            target={
              <UnstyledButton
                key={index}
                onClick={() => handleSelected(option)}
                sx={(theme) => ({
                  border: `1px solid ${theme.colors.brand[6]}`,
                  borderRadius: theme.radius.sm,
                  paddingLeft: theme.spacing.md,
                  paddingRight: theme.spacing.md,
                  paddingTop: 5,
                  paddingBottom: 5,
                  fontSize: theme.fontSizes.xs,
                  color: theme.colors.brand[6],
                  fontWeight: 600,
                })}
              >
                <Text size="sm">
                  {option.length} {option.length === 1 ? "Metre" : "Metres"}
                </Text>
              </UnstyledButton>
            }
          >
            <form onSubmit={form.onSubmit((values) => updateLength(values))}>
              <Box p="md" sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[3]}` })}>
                <Text weight={600} size="sm">
                  Edit Length
                </Text>
              </Box>
              <Box p="md">
                <NumberInput
                  required
                  id="costMultiplier"
                  name="costMultiplier"
                  label="Cost Multiplier"
                  description="The cost multiplier for this diameter of hose"
                  precision={2}
                  icon={<Calculator size={18} />}
                  {...form.getInputProps("costMultiplier")}
                />
                <Group position="right" mt="md">
                  <Button variant="subtle" onClick={removeLength}>
                    Delete
                  </Button>
                  <Button type="submit">Update</Button>
                </Group>
              </Box>
            </form>
          </Popover>
        ))}
      </Group>
    </Box>
  );
};

export default LengthList;
