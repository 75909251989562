import { Box, Button, Select, TextInput } from "@mantine/core";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hook";
import { RootState } from "../../../app/store";
import { updateSearchParams } from "../shipment-slice";
import ShipmentDatePicker from "./ShipmentDatePicker";

const SearchOptions = () => {
  const dispatch = useAppDispatch();
  const searchParams = useSelector((state: RootState) => state.shipmentSearch.searchParams);
  const [searchText, setSearchText] = useState(searchParams.filterText);

  return (
    <>
      <Box sx={{ gap: 5, display: "flex", flexWrap: "wrap", alignItems: "flex-end" }}>
        <ShipmentDatePicker />
        <Select
          label="Filter By"
          value={searchParams.filterBy}
          onChange={(value: string) => dispatch(updateSearchParams({ filterBy: value }))}
          sx={{ width: 120 }}
          data={[
            { value: "name", label: "Name" },
            { value: "orderRef", label: "Order Ref." },
            { value: "address", label: "Address" },
          ]}
        ></Select>
        <TextInput
          placeholder="Search shipments"
          label="Search"
          sx={{ width: 250 }}
          onKeyDown={(e) => {
            if (e.key === "Enter") dispatch(updateSearchParams({ filterText: searchText }));
          }}
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
        <Select
          label="Order by"
          value={searchParams.orderBy}
          onChange={(value: string) => dispatch(updateSearchParams({ orderBy: value }))}
          sx={{ width: 140 }}
          data={[
            { value: "orderRef", label: "By Order Ref." },
            { value: "date", label: "By Date" },
            { value: "name", label: "By Name" },
          ]}
        ></Select>
        <Button onClick={() => dispatch(updateSearchParams({ filterText: searchText }))}>Search</Button>
      </Box>
    </>
  );
};

export default SearchOptions;
