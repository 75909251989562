import { Box, Skeleton, Text, Paper, Group, useMantineTheme } from "@mantine/core";
import { Bar, BarChart, ResponsiveContainer } from "recharts";
import { ShipmentSummary } from "../../shipments/types";

interface Props {
  data?: ShipmentSummary;
  isLoading: boolean;
}
const ShipmentSummaryCard = ({ data, isLoading }: Props) => {
  const theme = useMantineTheme();

  return (
    <Paper shadow="sm" p="md" sx={{ textAlign: "center", height: 140 }}>
      <Skeleton visible={isLoading} height="100%">
        <Box>
          <Group position="apart">
            <Text size="xs" weight={600}>
              Shipments
            </Text>
            <Text size="xs" color="dimmed">
              Last 30 days
            </Text>
          </Group>
          <Text mt="xs" weight={700} align="left" size="xl">
            {data?.totalShipments}
          </Text>
          <Box mt="xs">
            <ResponsiveContainer width="100%" height={40}>
              <BarChart data={data?.parcelTypes} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                <Bar dataKey="total" fill={theme.colors.brand[7]} />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Box>
      </Skeleton>
    </Paper>
  );
};

export default ShipmentSummaryCard;
