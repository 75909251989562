import { Center, Loader } from "@mantine/core";

const PageLoading = () => {
  return (
    <Center style={{ height: 250 }}>
      <Loader variant="dots" />
    </Center>
  );
};

export default PageLoading;
