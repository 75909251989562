import { Box, Center, Loader, MantineProvider, Text } from "@mantine/core";

export const LoadingPage = () => {
  return (
    <div>
      <MantineProvider>
        <Center p={50}>
          <Box sx={{ textAlign: "center" }}>
            <Box>
              <Text weight={700} sx={{ fontSize: 40 }}>
                Shipmaster
              </Text>
            </Box>
            <Text size="xl" weight={600} mt={50}>
              Logging In
            </Text>
            <Text color="dimmed">Please use the pop-up to log in to your account.</Text>
            <Loader mt="lg" size="lg" variant="bars" />
          </Box>
        </Center>
      </MantineProvider>
    </div>
  );
};
