import React from "react";
import { Box, Skeleton, Text, Paper, Center, useMantineTheme } from "@mantine/core";
import moment from "moment";
import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from "recharts";
import { ShipmentSummary } from "../../shipments/types";

const formatXAxis = (tickItem: Date) => {
  return moment(tickItem).format("Do MMM");
};

const CustomTooltip = ({ active, payload, label }: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    return (
      <Paper shadow="xs" p="lg">
        <Center sx={{ height: "100%" }}>
          <Box>
            <Text weight={600}>{moment(label).format("ll")}</Text>
            {payload.map((pp, index) => (
              <Text size="sm" key={index}>{`${pp.name} : ${pp.value}`}</Text>
            ))}
          </Box>
        </Center>
      </Paper>
    );
  }

  return null;
};

interface Props {
  data?: ShipmentSummary;
  isLoading: boolean;
}
const ParcelSummaryChart = ({ data, isLoading }: Props) => {
  const theme = useMantineTheme();

  return (
    <Skeleton visible={!data && isLoading} height="100%">
      <ResponsiveContainer width="100%" height={280}>
        <BarChart data={data?.parcelTypes} barSize={15} margin={{ top: 0, right: 0, left: 0, bottom: 25 }}>
          <Tooltip cursor={false} content={<CustomTooltip />} />
          <Bar dataKey="largeLetters" name="Large Letters" stackId="a" fill={theme.colors.brand[7]} isAnimationActive={false} />
          <Bar dataKey="parcels" name="Parcels" stackId="a" fill={theme.colors.brand[4]} isAnimationActive={false} />
          <XAxis
            height={30}
            tick={{ fontSize: 12 }}
            dataKey="date"
            tickFormatter={formatXAxis}
            axisLine={false}
            tickMargin={10}
            padding={{ left: 10, right: 10 }}
          />
          <YAxis
            dataKey="total"
            width={30}
            type="number"
            interval={0}
            domain={["auto", "auto"]}
            axisLine={false}
            tickLine={false}
            tick={{ fontSize: 12 }}
            padding={{ top: 10, bottom: 5 }}
          />
          <Legend verticalAlign="bottom" height={20} iconType="circle" iconSize={8} wrapperStyle={{ position: "relative" }} />
        </BarChart>
      </ResponsiveContainer>
    </Skeleton>
  );
};

export default ParcelSummaryChart;
