import { Skeleton, Text, Paper, useMantineTheme } from "@mantine/core";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { ShipmentSummary } from "../../shipments/types";

interface Props {
  data?: ShipmentSummary;
  isLoading: boolean;
}
const ServiceTypeCard = ({ data, isLoading }: Props) => {
  const theme = useMantineTheme();
  const chartColors = [theme.colors.brand[7], theme.colors.brand[3], theme.colors.orange[6], theme.colors.orange[3]];

  return (
    <Paper p="xl" shadow="sm">
      <Text mb="lg" weight={600}>
        Service Types (Past 30 days)
      </Text>
      <Skeleton visible={isLoading} height="100%">
        <ResponsiveContainer width="100%" height={310}>
          <PieChart>
            <Pie
              data={data?.serviceCountSummary}
              startAngle={90}
              endAngle={450}
              cy={100}
              innerRadius={60}
              outerRadius={90}
              fill="#8884d8"
              paddingAngle={2}
              dataKey="count"
            >
              {data?.serviceCountSummary &&
                data.serviceCountSummary.map((entry, index) => (
                  <Cell key={`cell-${index}`} name={entry.serviceName} fill={chartColors[index % chartColors.length]} />
                ))}
            </Pie>
            <Legend verticalAlign="bottom" iconType="circle" iconSize={8} />
          </PieChart>
        </ResponsiveContainer>
      </Skeleton>
    </Paper>
  );
};

export default ServiceTypeCard;
