import { ReactElement, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Grid, Paper, Skeleton, Stack, Text } from "@mantine/core";
import PageTitle, { PageTitleSkeleton } from "../../../components/Elements/PageTitle";
import TrackingHistory from "../components/TrackingHistory";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { getShipmentById, getShipmentTracking } from "../api/shipmentAsyncActions";

const ShipmentDetail = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const shipment = useAppSelector((state) => state.shipmentSearch.shipment);
  const tracking = useAppSelector((state) => state.shipmentSearch.tracking);

  useEffect(() => {
    const execute = async () => {
      const result = await dispatch(getShipmentById(id || ""));

      if (getShipmentById.fulfilled.match(result)) {
        dispatch(getShipmentTracking(result.payload));
      }
    };

    execute();
  }, [dispatch, id]);

  if (shipment.loading || shipment.data === undefined) {
    return <PageLoading />;
  }

  return (
    <>
      <PageTitle area="Shipment Details" title={`Shipment ${id}`} />
      <Grid>
        <Grid.Col xs={12} lg={5}>
          <Paper shadow="xs" p={30}>
            <Text weight={600}>Shipment Details</Text>
            <Grid mt={15}>
              <Grid.Col span={5}>
                <Text size="sm">Dispatched Date</Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <Text size="sm">{moment(shipment.data.shipmentDate).format("LLL")}</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <Text size="sm">Courier</Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <Text size="sm">{shipment.data?.courierService?.courier?.name}</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <Text size="sm">Service Name</Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <Text size="sm">{shipment.data?.courierService?.serviceName}</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <Text size="sm">Tracking Number</Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <Text size="sm">{shipment.data?.trackingNumber}</Text>
              </Grid.Col>
              <Grid.Col span={5}>
                <Text size="sm">Delivery Address</Text>
              </Grid.Col>
              <Grid.Col span={7}>
                <Text size="sm">{shipment.data.address.customerName}</Text>
                <Text size="sm" weight={600}>
                  {shipment.data.address.company}
                </Text>
                <Text size="sm">{shipment.data.address.street1}</Text>
                {shipment.data.address.street2 !== "" && <Text size="sm">{shipment.data.address.street2}</Text>}
                {shipment.data.address.street3 !== "" && <Text size="sm">{shipment.data.address.street3}</Text>}
                <Text size="sm">{shipment.data.address.city}</Text>
                <Text size="sm">{shipment.data.address.postcode}</Text>
                <Text size="sm">{shipment.data.address.country.countryName}</Text>
              </Grid.Col>
              <Button
                mt={25}
                component="a"
                href={`https://www3.royalmail.com/track-your-item#/tracking-results/${shipment.data.trackingNumber}`}
                rel="noopener"
                target="_blank"
                size="md"
                fullWidth
              >
                View on Royal Mail
              </Button>
            </Grid>
          </Paper>
        </Grid.Col>
        <Grid.Col xs={12} lg={7}>
          <Paper shadow="xs" p={30}>
            <Text weight={600}>Tracking</Text>
            <TrackingHistory data={tracking.data} loading={tracking.loading} />
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
};

const PageLoading = () => {
  return (
    <>
      <PageTitleSkeleton></PageTitleSkeleton>
      <Grid>
        <Grid.Col span={5}>
          <Paper shadow="xs" p={30}>
            <Text weight={600}>Shipment Details</Text>
            <Stack mt={15}>
              <Skeleton height={30} />
              <Skeleton height={30} />
              <Skeleton height={30} />
              <Skeleton height={30} />
              <Skeleton height={30} />
              <Skeleton height={250} sx={{ marginTop: 1 }} />
              <Skeleton height={50} sx={{ marginTop: 2 }} />
            </Stack>
          </Paper>
        </Grid.Col>
        <Grid.Col xs={7}>
          <Paper shadow="xs" p={30}>
            <Text weight={600}>Tracking</Text>
            <Stack mt={15}>
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
              <Skeleton height={80} />
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default connect()(ShipmentDetail);
