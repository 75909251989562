import { Box, Button } from "@mantine/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../../app/hook";
import { RootState } from "../../../app/store";
import PageTitle from "../../../components/Elements/PageTitle";
import { getHoseGroups } from "../api/listingAsyncActions";
import ProductGroupList from "../components/ProductGroupList";

const Overview = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getHoseGroups());
  }, [dispatch]);

  const data = useSelector((state: RootState) => state.listing);

  return (
    <>
      <PageTitle area="Listings" title="Overview" />
      <Box mb={3}>
        <Button component={Link} to={"/listing/create"}>
          Create Listing
        </Button>
      </Box>

      <ProductGroupList hoseGroups={data.hoseGroups.data} isLoading={data.hoseGroups.loading} />
    </>
  );
};

export default Overview;
