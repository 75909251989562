import { ReactElement } from "react";
import { Link, useParams } from "react-router-dom";
import PageTitle from "../../../components/Elements/PageTitle";
import { useGetProductGroupByIdQuery } from "../api/stockApi-slice";
import SearchBox from "../components/SearchBox";
import { Product, StockItem } from "../types";
import { Anchor, Grid, Paper, Table, Text, Image, Stack, Button, Box, Divider } from "@mantine/core";

const ProductGroup = (): ReactElement => {
  const { id } = useParams();
  const { data, isLoading } = useGetProductGroupByIdQuery(id || "");

  if (isLoading || data === undefined) {
    return <></>;
  }

  function renderRow(item: Product) {
    let supplier: StockItem | undefined;

    // Order suppliers by ascending cost price
    if (item.stockItems.length > 0) {
      supplier = item.stockItems.reduce((prev, current) => {
        return prev.costPrice < current.costPrice ? prev : current;
      });
    }

    return (
      <>
        <td>{item.sku}</td>
        <td>{item.name}</td>
        <td>{supplier ? supplier.supplierName : "-"}</td>
        <td>{supplier ? supplier.stockCode : "-"}</td>
        <td align="right">{supplier && supplier.costPrice !== 0 ? `£${supplier.costPrice.toFixed(2)}` : "-"}</td>
        <td align="right">{`£${item.price.toFixed(2)}`}</td>
      </>
    );
  }

  function renderTable() {
    if (data?.products.length === 0) {
      return <Text>No products found.</Text>;
    }

    return (
      <Table verticalSpacing="md">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Product Name</th>
            <th>Supplier</th>
            <th>Supplier Code</th>
            <th style={{ textAlign: "right" }}>Cost Price</th>
            <th style={{ textAlign: "right" }}>Listed Price</th>
          </tr>
        </thead>
        <tbody>
          {data?.products.map((item) => (
            <tr key={item.productId}>{renderRow(item)}</tr>
          ))}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      <PageTitle title={data.name} area="Product Group" />
      <SearchBox />
      <Grid>
        <Grid.Col md={4} xs={12}>
          <Paper shadow="sm">
            <Image src={data.imageURL} alt={data.name} />
          </Paper>

          {data.categories.length > 0 && (
            <>
              <Paper mt="md" shadow="sm">
                <Stack spacing="xs">
                  <Text p="md" pb={0} size="xs" color="dimmed">
                    Listed Categories
                  </Text>
                  {data.categories.map((category, index) => (
                    <div key={index}>
                      <Box
                        sx={(theme) => ({
                          "&:hover": {
                            backgroundColor: theme.colors.gray[1],
                          },
                        })}
                      >
                        <Anchor component={Link} to={`/stock/category/${category.categoryId}`} style={{ textDecoration: "none" }}>
                          <Text px="md" p="xs" size="sm" color="black">
                            {category.name}
                          </Text>
                        </Anchor>
                      </Box>
                      <Divider />
                    </div>
                  ))}
                </Stack>
              </Paper>
              <Button size="md" mt="md" fullWidth variant="outline" component="a" href={data.url} target="_blank">
                View on The Hosemaster
              </Button>
            </>
          )}
        </Grid.Col>
        <Grid.Col md={8} xs={12}>
          <Paper p="lg" shadow="sm">
            {renderTable()}
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default ProductGroup;
