import { createSlice } from "@reduxjs/toolkit";
import { getHoseGroups, getHoseGroup } from "./api/listingAsyncActions";
import { HoseGroup } from "./types";

interface ListingState {
  hoseGroup: {
    data?: HoseGroup;
    loading: boolean;
  };
  hoseGroups: {
    data: HoseGroup[];
    loading: boolean;
  };
  loading: boolean;
}

const initialState: ListingState = {
  hoseGroup: {
    data: undefined,
    loading: false,
  },
  hoseGroups: {
    data: [],
    loading: false,
  },
  loading: false,
};

const listingSlice = createSlice({
  name: "listing",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getHoseGroups.pending, (state, action) => {
        state.hoseGroups.loading = true;
      })
      .addCase(getHoseGroups.fulfilled, (state, action) => {
        state.hoseGroups.data = action.payload;
        state.hoseGroups.loading = false;
      })
      .addCase(getHoseGroups.rejected, (state, action) => {
        state.hoseGroups.loading = false;
      })
      .addCase(getHoseGroup.pending, (state, action) => {
        state.hoseGroup.loading = true;
      })
      .addCase(getHoseGroup.fulfilled, (state, action) => {
        state.hoseGroup.data = action.payload;
        state.hoseGroup.loading = false;
      })
      .addCase(getHoseGroup.rejected, (state, action) => {
        state.hoseGroup.loading = false;
      });
  },
});

export default listingSlice.reducer;
