import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Anchor, Paper, Text, Image, Box, useMantineTheme } from "@mantine/core";

interface Props {
  id: number;
  name: string;
  imageUrl: string;
}

const ProductGroupListItem = (props: Props): ReactElement => {
  const theme = useMantineTheme();

  return (
    <>
      <Box mb="lg">
        <Anchor component={Link} to={`/stock/productgroup/${props.id}`} style={{ textDecoration: "none" }}>
          <Paper shadow="sm" sx={{ borderRadius: theme.radius.md }}>
            <Image radius="md" src={props.imageUrl} alt={props.name} withPlaceholder />
          </Paper>
          <Text size="sm" weight={600} color={theme.black} mt="xl">
            {props.name}
          </Text>
        </Anchor>
      </Box>
    </>
  );
};

export default ProductGroupListItem;
