import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getShipmentById, searchShipments, getShipmentTracking } from "./api/shipmentAsyncActions";
import { Shipment, ShipmentSearchParams, ShipmentTracking } from "./types";

interface ShipmentSearchState {
  searchParams: ShipmentSearchParams;
  shipments: Shipment[];
  totalItemCount: number;
  shipment: {
    data?: Shipment;
    loading: boolean;
  };
  tracking: {
    data?: ShipmentTracking;
    loading: boolean;
  };
  loading: boolean;
}

const initialState: ShipmentSearchState = {
  searchParams: {
    dateFrom: new Date().toISOString().substring(0, 10), // today
    dateTo: new Date().toISOString().substring(0, 10), // today
    filterBy: "name",
    filterText: "",
    orderBy: "orderRef",
    page: 1,
    itemsPerPage: 100,
  },
  shipments: [],
  totalItemCount: 0,
  shipment: {
    data: undefined,
    loading: false,
  },
  tracking: {
    data: undefined,
    loading: false,
  },
  loading: false,
};

const shipmentSearchSlice = createSlice({
  name: "shipmentSearch",
  initialState,
  reducers: {
    updateDateRange(state, action: PayloadAction<{ dateTo: string; dateFrom: string }>) {
      state.searchParams.page = 1;

      state.searchParams.dateTo = action.payload.dateTo;
      state.searchParams.dateFrom = action.payload.dateFrom;
    },
    updatePage(state, action: PayloadAction<number>) {
      state.searchParams.page = action.payload;
    },
    updateItemsPerPage(state, action: PayloadAction<number>) {
      state.searchParams.page = 1;
      state.searchParams.itemsPerPage = action.payload;
    },
    updateSearchParams(state, action: PayloadAction<Partial<ShipmentSearchParams>>) {
      state.searchParams = { ...state.searchParams, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getShipmentById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getShipmentById.fulfilled, (state, action) => {
      state.shipment.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getShipmentById.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(searchShipments.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(searchShipments.fulfilled, (state, action) => {
      state.shipments = action.payload.shipments;
      state.totalItemCount = action.payload.totalItemCount;
      state.loading = false;
    });
    builder.addCase(searchShipments.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getShipmentTracking.pending, (state, action) => {
      state.tracking.loading = true;
    });
    builder.addCase(getShipmentTracking.fulfilled, (state, action) => {
      state.tracking.data = action.payload;
      state.tracking.loading = false;
    });
    builder.addCase(getShipmentTracking.rejected, (state, action) => {
      state.tracking.loading = false;
    });
  },
});

export const { updatePage, updateItemsPerPage, updateSearchParams, updateDateRange } = shipmentSearchSlice.actions;

export default shipmentSearchSlice.reducer;
