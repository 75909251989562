import React from "react";
import { connect } from "react-redux";
import PageTitle from "../../components/Elements/PageTitle";

const LoggedIn = () => {
  return (
    <div>
      <PageTitle area="Auth" title="Logged In" />
    </div>
  );
};

export default connect()(LoggedIn);
