import { ReactElement } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../components/Elements/PageTitle";
import CategoryList from "../components/CategoryList";
import ProductGroupList from "../components/ProductGroupList";
import { useGetCategoryQuery } from "../api/stockApi-slice";
import { Grid } from "@mantine/core";
import PageLoading from "../../../components/Elements/PageLoading";

const Category = (): ReactElement => {
  const { id } = useParams();
  const { data } = useGetCategoryQuery(id || "");

  //Check for ID
  if (id === undefined || data === undefined) {
    return <PageLoading />;
  }

  return (
    <>
      <PageTitle title={data?.name || ""} area="Category" />
      <Grid>
        <Grid.Col xs={12} md={4} lg={3}>
          <CategoryList id={id} />
        </Grid.Col>
        <Grid.Col xs={12} md={8} lg={9} mt="xl">
          <ProductGroupList id={id} />
        </Grid.Col>
      </Grid>
    </>
  );
};

export default Category;
