import { useEffect } from "react";
import { Button, Divider, Grid, Group, Paper, Stack } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PageTitle from "../../../components/Elements/PageTitle";
import { HoseGroup } from "../types";
import DiameterList from "../components/DiameterList";
import LengthList from "../components/LengthList";
import AddLengthForm from "../components/AddLengthForm";
import AddDiameterForm from "../components/AddDiameterForm";
import { RootState } from "../../../app/store";
import { useAppDispatch } from "../../../app/hook";
import { generateOptions, resetState, setDescription, setSkuPrefix, toggleEnabled } from "../draftListing-slice";
import HoseDetailForm from "../components/HoseDetailForm";
import { MapToHoseListings } from "../helpers";
import { createHoseGroup } from "../api/listingAsyncActions";
import HoseOptionsTable from "../components/HoseOptionsTable";

const CreateListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const draft = useSelector((state: RootState) => state.draftListing.draft);
  const isLoading = useSelector((state: RootState) => state.draftListing.loading);

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  const updateHoseOptions = (values: { description: string; skuPrefix: string }) => {
    dispatch(setDescription(values.description));
    dispatch(setSkuPrefix(values.skuPrefix));
    dispatch(generateOptions());
  };

  const createListing = async () => {
    const newHoseGroup: HoseGroup = {
      id: 0,
      description: draft.description,
      skuPrefix: draft.skuPrefix,
      listings: MapToHoseListings(draft.hoseOptions),
      diameters: draft.diameters,
      lengths: draft.lengths,
    };

    dispatch(createHoseGroup(newHoseGroup)).then(() => {
      navigate(`/listing`);
      dispatch(resetState());
    });
  };

  return (
    <>
      <PageTitle area="Listings" title="Create Listing" />
      <Grid>
        <Grid.Col xs={4}>
          <AddDiameterForm />
        </Grid.Col>

        <Grid.Col xs={4}>
          <AddLengthForm />
        </Grid.Col>

        <Grid.Col xs={4}>
          <Paper shadow="xs" p="lg">
            <Stack spacing={15}>
              <DiameterList diameters={draft.diameters} />
              <Divider my="sm" variant="dashed" />
              <LengthList lengths={draft.lengths} />
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>

      {draft.diameters.length > 0 && draft.lengths.length > 0 && (
        <HoseDetailForm skuPrefix={draft.skuPrefix} description={draft.description} onSubmit={updateHoseOptions} />
      )}

      {draft.hoseOptions.length > 0 && (
        <>
          <HoseOptionsTable hoseOptions={draft.hoseOptions} toggleEnabled={(hoseOption) => dispatch(toggleEnabled(hoseOption))} />

          <Group position="right">
            <Button variant="outline" mt="lg" onClick={createListing} disabled={isLoading}>
              Create Product Group
            </Button>
          </Group>
        </>
      )}
    </>
  );
};

export default CreateListing;
