import { Anchor, Box, Group, Paper, Stack, Text, useMantineTheme } from "@mantine/core";
import { ReactElement } from "react";
import { Link } from "react-router-dom";
import { ArrowBackUp } from "tabler-icons-react";
import { useGetCategoryQuery } from "../api/stockApi-slice";
import SearchBox from "./SearchBox";

interface Props {
  id: string;
}

const CategoryList = (props: Props): ReactElement => {
  const theme = useMantineTheme();
  const { data } = useGetCategoryQuery(props.id);

  function renderParentCategoryLink() {
    if (data?.parentCategory !== null) {
      return (
        <>
          <Paper p="sm" shadow="xs" mb="md">
            <Anchor component={Link} to={`/stock/category/${data?.parentCategory.categoryId}`} style={{ textDecoration: "none" }}>
              <Text size="sm" weight={600}>
                <Group>
                  <ArrowBackUp size={20} />
                  <Text size="sm">{data?.parentCategory.name}</Text>
                </Group>
              </Text>
            </Anchor>
          </Paper>
        </>
      );
    } else {
      return (
        <>
          <Paper p="sm" shadow="xs" mb="md">
            <Anchor component={Link} to={`/stock/`} style={{ textDecoration: "none" }}>
              <Text size="sm" weight={600}>
                <Group>
                  <ArrowBackUp size={20} />
                  <Text size="sm">Back</Text>
                </Group>
              </Text>
            </Anchor>
          </Paper>
        </>
      );
    }
  }

  return (
    <>
      <Stack spacing={0}>
        <SearchBox />
        {renderParentCategoryLink()}
        {data?.childCategories.map((c, index) => (
          <Box
            key={index}
            pl="lg"
            py={7}
            sx={{
              borderLeft: `1px solid ${theme.colors.gray[4]}`,
              textDecoration: "none",
              "&:hover": {
                backgroundColor: theme.colors.red[1],
                textDecoration: "none",
              },
              "&.active": {
                backgroundColor: theme.colors.gray[0],
                fontWeight: 700,
              },
            }}
          >
            <Anchor key={c.categoryId} component={Link} to={`/stock/category/${c.categoryId}`} style={{ textDecoration: "none" }}>
              <Text color={theme.black} size="sm">
                {c.name}
              </Text>
            </Anchor>
          </Box>
        ))}
      </Stack>
    </>
  );
};

export default CategoryList;
