import { Box, Button, Group, NumberInput, Paper } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { ArrowsDiagonal2, Calculator, CurrencyPound, Scale } from "tabler-icons-react";
import { useAppDispatch } from "../../../app/hook";
import { addDiameter } from "../draftListing-slice";

interface DiameterForm {
  innerDiameter: number;
  costPerMetre: number;
  costMultiplier: number;
  weightPerMetre: number;
}

const AddDiameterForm = () => {
  const dispatch = useAppDispatch();

  const form = useForm({
    initialValues: {
      innerDiameter: 5,
      costPerMetre: 1,
      costMultiplier: 2,
      weightPerMetre: 1,
    },
  });

  const handleSubmit = ({ innerDiameter, costPerMetre, costMultiplier, weightPerMetre }: DiameterForm) => {
    dispatch(addDiameter({ id: 0, innerDiameter, costPerMetre, costMultiplier, weightPerMetre }));
  };

  return (
    <Paper shadow="xs" p="lg">
      <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
        <NumberInput
          required
          id="innerDiameter"
          name="innerDiameter"
          label="Inner Diameter (mm)"
          description="The diameter of the inner of the hose"
          precision={2}
          icon={<ArrowsDiagonal2 size={18} />}
          {...form.getInputProps("innerDiameter")}
        />
        <NumberInput
          required
          mt="lg"
          id="costPerMetre"
          name="costPerMetre"
          label="Cost (per metre)"
          description="The cost per metre of hose"
          precision={2}
          icon={<CurrencyPound size={18} />}
          {...form.getInputProps("costPerMetre")}
        />
        <NumberInput
          required
          mt="lg"
          id="weightPerMetre"
          name="weightPerMetre"
          label="Weight in kg (per metre)"
          description="The weight per metre of hose (in kg)"
          precision={2}
          step={0.05}
          icon={<Scale size={18} />}
          {...form.getInputProps("weightPerMetre")}
        />
        <NumberInput
          required
          mt="lg"
          id="costMultiplier"
          name="costMultiplier"
          label="Cost Multiplier"
          description="The cost multiplier for this diameter of hose"
          precision={2}
          icon={<Calculator size={18} />}
          {...form.getInputProps("costMultiplier")}
        />

        <Group position="right" mt="lg">
          <Button type="submit">Add Diameter</Button>
        </Group>
      </Box>
    </Paper>
  );
};

export default AddDiameterForm;
