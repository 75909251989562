import { Box, Button, Group, Table } from "@mantine/core";
import { useEffect } from "react";
import NumberFormat from "react-number-format";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Download, Edit, Trash } from "tabler-icons-react";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import PageLoading from "../../../components/Elements/PageLoading";
import PageTitle from "../../../components/Elements/PageTitle";
import { deleteHoseGroup, getHoseGroup } from "../api/listingAsyncActions";
import { HoseGroup, HoseListing } from "../types";

const Listing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const hoseGroup = useAppSelector((state) => state.listing.hoseGroup);

  useEffect(() => {
    dispatch(getHoseGroup(params.id || ""));
  }, [dispatch, params.id]);

  if (hoseGroup.loading || hoseGroup.data === undefined) {
    return <PageLoading />;
  }

  const deleteListing = () => {
    // delete listing

    dispatch(deleteHoseGroup(params.id || "")).then(() => {
      navigate("/listing");
    });
  };

  return (
    <>
      <PageTitle area="Listings" title={hoseGroup.data?.description || ""} />
      <Group position="apart">
        <Group>
          <Button component={Link} to={`/listing/edit/${params.id}`} leftIcon={<Edit size="16" />}>
            Edit
          </Button>
          <Button onClick={deleteListing} leftIcon={<Trash size="16" />}>
            Delete
          </Button>
        </Group>
        <Group position="right">
          <Button variant="outline" leftIcon={<Download size="16" />} onClick={() => downloadForWeb(hoseGroup.data!)}>
            Download for Website
          </Button>
          <Button variant="outline" leftIcon={<Download size="16" />} onClick={() => downloadForOgl(hoseGroup.data!)}>
            Download for OGL
          </Button>
          <Button variant="outline" leftIcon={<Download size="16" />} onClick={() => downloadCsv(hoseGroup.data!)}>
            Download CSV
          </Button>
        </Group>
      </Group>
      <Table mt="md" horizontalSpacing={0} verticalSpacing="sm">
        <thead>
          <tr>
            <th>SKU</th>
            <th>Description</th>
            <th style={{ textAlign: "right" }}>Inner Diameter</th>
            <th style={{ textAlign: "right" }}>Length</th>
            <th style={{ textAlign: "right" }}>Price</th>
            <th style={{ textAlign: "right" }}>Cost</th>
            <th style={{ textAlign: "right" }}>Weight</th>
            <th style={{ textAlign: "right" }}>Enabled?</th>
          </tr>
        </thead>
        <tbody>
          {hoseGroup.data?.listings.map((hoseOption, index) => (
            <Box component="tr" key={index} sx={(theme) => ({ color: hoseOption.enabled ? theme.colors.black : theme.colors.gray[5] })}>
              <td>{hoseOption.sku}</td>
              <td>{hoseOption.name}</td>
              <td align="right">{hoseOption.innerDiameter}mm</td>
              <td align="right">
                {hoseOption.length} {hoseOption.length === 1 ? "Metre" : "Metres"}
              </td>
              <td align="right">
                <NumberFormat
                  value={hoseOption.price}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"£"}
                  allowLeadingZeros
                  fixedDecimalScale
                  decimalScale={2}
                />
              </td>
              <td align="right">
                <NumberFormat
                  value={hoseOption.cost}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"£"}
                  allowLeadingZeros
                  fixedDecimalScale
                  decimalScale={2}
                />
              </td>
              <td align="right">
                <NumberFormat
                  value={hoseOption.weight || 0}
                  displayType={"text"}
                  thousandSeparator={true}
                  suffix={"kg"}
                  allowLeadingZeros
                  decimalScale={2}
                />
              </td>
              <td align="right">{hoseOption.enabled ? "Enabled" : "Disabled"}</td>
            </Box>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default Listing;

const downloadCsv = (hoseGroup: HoseGroup) => {
  let csv = "sku,name,price,cost,diameter,diameter_value,length,length_value,weight,status\n";

  hoseGroup.listings.forEach((listing: HoseListing) => {
    // add to csv
    csv += `${listing.sku},"${listing.name}",${listing.price},${listing.cost},${listing.innerDiameter}mm,${listing.innerDiameter},${
      listing.length
    } ${listing.length === 1 ? "Metre" : "Metres"},${listing.length},${listing.weight},${listing.enabled ? "Enabled" : "Disabled"}\n`;
  });
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${hoseGroup.skuPrefix}.csv`;
  link.click();
};

const downloadForWeb = (hoseGroup: HoseGroup) => {
  let csv =
    "sku,attribute_set_code,product_type,product_websites,name,weight,product_online,tax_class_name,visibility,price,qty,categories,hose_inner_diameter,hose_length\n";

  hoseGroup.listings.forEach((listing: HoseListing) => {
    // add to csv
    csv += `${listing.sku},Config Hose,simple,base,"${listing.name}",${listing.weight},${
      listing.enabled ? 1 : 0
    },Taxable Goods,Not Visible Individually,${listing.price},0,"",${listing.innerDiameter}mm,${listing.length} ${
      listing.length === 1 ? "Metre" : "Metres"
    }\n`;
  });
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${hoseGroup.skuPrefix}_WEB.csv`;
  link.click();
};

const downloadForOgl = (hoseGroup: HoseGroup) => {
  let csv = "SKU,Price Sell,Title,Title 2,Product Group,Cost,Weight\n";

  hoseGroup.listings.forEach((listing: HoseListing) => {
    const title = listing.name.substring(0, 30);
    const title2 = listing.name.substring(30, 60);
    // add to csv
    csv += `${listing.sku},${listing.price},"${title}","${title2}",HOS,${listing.cost},${listing.weight}\n`;
  });
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `${hoseGroup.skuPrefix}_OGL.csv`;
  link.click();
};
