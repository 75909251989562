import { Box, Pagination, Select, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { RootState } from "../../../app/store";
import { updateItemsPerPage, updatePage } from "../shipment-slice";

const ShipmentTableControls = () => {
  const dispatch = useAppDispatch();
  const shipmentOptions = useSelector((state: RootState) => state.shipmentSearch.searchParams);
  const data = useAppSelector((state) => state.shipmentSearch);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    if (data === undefined) return;
    setPageCount(Math.ceil(data.totalItemCount / shipmentOptions.itemsPerPage));
  }, [data, shipmentOptions.itemsPerPage]);

  return (
    <Box
      sx={{
        gap: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Text size="sm">{!data?.totalItemCount ? "0" : data.totalItemCount} Results</Text>
      <Box sx={{ display: "flex", gap: 20 }}>
        <Select
          value={shipmentOptions.itemsPerPage.toString()}
          onChange={(value: string) => dispatch(updateItemsPerPage(parseInt(value)))}
          sx={{ width: 140 }}
          data={[
            { value: "100", label: "Show 100" },
            { value: "200", label: "Show 200" },
            { value: "500", label: "Show 500" },
          ]}
        ></Select>

        {pageCount > 1 && (
          <Pagination
            size="md"
            radius="xs"
            spacing={5}
            total={pageCount}
            page={shipmentOptions.page}
            onChange={(value) => dispatch(updatePage(value))}
            sx={(theme) => ({
              item: { color: "brand", fontSize: 11 },
              dots: { color: "brand" },
              active: { color: "brand" },
            })}
          />
        )}
      </Box>
    </Box>
  );
};

export default ShipmentTableControls;
