import { Button, Divider, Grid, Group, Paper, Stack } from "@mantine/core";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hook";
import { RootState } from "../../../app/store";
import PageTitle from "../../../components/Elements/PageTitle";
import {
  generateOptions,
  toggleEnabled,
  setDescription,
  setSkuPrefix,
  resetState,
  updateDiameters,
  updateLengths,
  setHoseOptions,
} from "../draftListing-slice";
import AddDiameterForm from "../components/AddDiameterForm";
import AddLengthForm from "../components/AddLengthForm";
import DiameterList from "../components/DiameterList";
import LengthList from "../components/LengthList";
import { useNavigate, useParams } from "react-router-dom";
import { HoseGroup } from "../types";
import PageLoading from "../../../components/Elements/PageLoading";
import { getHoseGroup, updateHoseGroup } from "../api/listingAsyncActions";
import HoseDetailForm from "../components/HoseDetailForm";
import HoseOptionsTable from "../components/HoseOptionsTable";
import { MapToHoseListings } from "../helpers";

const EditListing = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const params = useParams();
  const draft = useSelector((state: RootState) => state.draftListing.draft);
  const isLoading = useSelector((state: RootState) => state.draftListing.loading);

  useEffect(() => {
    dispatch(resetState());
    const execute = async () => {
      const result = await dispatch(getHoseGroup(params.id || ""));

      if (getHoseGroup.fulfilled.match(result)) {
        dispatch(setDescription(result.payload.description));
        dispatch(setSkuPrefix(result.payload.skuPrefix));
        dispatch(updateDiameters(result.payload.diameters));
        dispatch(updateLengths(result.payload.lengths));
        dispatch(setHoseOptions(result.payload.listings));
      }
    };

    execute();
  }, [dispatch, params.id]);

  const updateListing = () => {
    const updatedHoseGroup: HoseGroup = {
      id: Number(params.id),
      description: draft.description,
      skuPrefix: draft.skuPrefix,
      listings: MapToHoseListings(draft.hoseOptions),
      diameters: draft.diameters,
      lengths: draft.lengths,
    };

    dispatch(updateHoseGroup(updatedHoseGroup)).then(() => {
      navigate(`/listing/${updatedHoseGroup.id}`);
      dispatch(resetState());
    });
  };

  const updateHoseOptions = (values: { description: string; skuPrefix: string }) => {
    dispatch(setDescription(values.description));
    dispatch(setSkuPrefix(values.skuPrefix));

    dispatch(generateOptions());
  };

  if (isLoading) return <PageLoading />;

  return (
    <>
      <PageTitle area="Listings" title="Edit Listing" />
      <Grid>
        <Grid.Col xs={4}>
          <AddDiameterForm />
        </Grid.Col>

        <Grid.Col xs={4}>
          <AddLengthForm />
        </Grid.Col>

        <Grid.Col xs={4}>
          <Paper shadow="xs" p="lg">
            <Stack spacing={15}>
              <DiameterList diameters={draft.diameters} />
              <Divider my="sm" variant="dashed" />
              <LengthList lengths={draft.lengths} />
            </Stack>
          </Paper>
        </Grid.Col>
      </Grid>

      {draft.diameters.length > 0 && draft.lengths.length > 0 && (
        <HoseDetailForm skuPrefix={draft.skuPrefix} description={draft.description} onSubmit={updateHoseOptions} />
      )}

      {draft.diameters.length > 0 && draft.lengths.length > 0 && draft.hoseOptions.length > 0 && (
        <>
          <HoseOptionsTable hoseOptions={draft.hoseOptions} toggleEnabled={(hoseOption) => dispatch(toggleEnabled(hoseOption))} />

          <Group position="right">
            <Button variant="outline" mt="lg" onClick={updateListing} disabled={isLoading}>
              Update Listing
            </Button>
          </Group>
        </>
      )}
    </>
  );
};

export default EditListing;
