import { Box, Skeleton, Text, Paper, Center, useMantineTheme } from "@mantine/core";
import { ShipmentSummary } from "../../shipments/types";

// get perentage increase from previous period
const PercentChange = ({ data }: { data: ShipmentSummary }) => {
  const theme = useMantineTheme();

  const percent = (data.totalShipments - data.totalShipmentsPreviousPeriod) / data.totalShipments;
  if (percent > 0) {
    return (
      <Text sx={{ fontSize: 32 }} weight={700} color={theme.colors.green[7]}>
        {(percent * 100).toFixed(1)}%
      </Text>
    );
  } else {
    return (
      <Text sx={{ fontSize: 46 }} weight={700} color={theme.colors.red[7]}>
        {(percent * 100).toFixed(1)}%
      </Text>
    );
  }
};

interface Props {
  data?: ShipmentSummary;
  isLoading: boolean;
}
const PreviousPeriodCard = ({ data, isLoading }: Props) => {
  return (
    <Paper p="xl" shadow="sm" sx={{ height: "100%" }}>
      <Skeleton visible={isLoading} sx={{ textAlign: "center", height: "100%" }}>
        {data && (
          <>
            <Center sx={{ height: "100%" }}>
              <Box>
                <Text sx={{ fontSize: 32 }} weight={700}>
                  {data?.totalShipments}
                </Text>
                <Text size="sm">Shipments Past 30 Days</Text>
                <PercentChange data={data} />
                <Text weight={600} size="sm">
                  {data?.totalShipmentsPreviousPeriod} in Previous Period
                </Text>
              </Box>
            </Center>
          </>
        )}
      </Skeleton>
    </Paper>
  );
};

export default PreviousPeriodCard;
