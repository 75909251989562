import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Dashboard from "../features/dashboard/routes/Dashboard";
import Shipments from "../features/shipments/routes/Shipments";
import LoggedIn from "../features/auth/LoggedIn";
import { connect } from "react-redux";
import { useAppDispatch } from "./hook";
import { Profile, updateProfile } from "../features/auth/auth-slice";
import ShipmentDetail from "../features/shipments/routes/ShipmentDetail";
import Stock from "../features/stock/routes/Stock";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Category from "../features/stock/routes/Category";
import ProductGroup from "../features/stock/routes/ProductGroup";
import ProductSearch from "../features/stock/routes/ProductSearch";
import Overview from "../features/listing/routes/Overview";
import CreateListing from "../features/listing/routes/CreateListing";
import Listing from "../features/listing/routes/Listing";
import { createStyles } from "@mantine/core";
import EditListing from "../features/listing/routes/EditListing";
import { AppContainer } from "../components/Layout/AppContainer";

const useStyles = createStyles((theme, _params, getRef) => ({
  wrapper: {
    fontFamily: "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji",
  },
}));

const App = () => {
  const { instance, accounts } = useMsal();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const [authorized, setAuthorized] = useState(false);

  useEffect(getProfile, [accounts, instance, dispatch]);

  function getProfile() {
    const account = instance.getAllAccounts()[0];
    const profile: Profile = {
      name: account!.name,
      email: account!.username,
    };

    dispatch(updateProfile(profile));
    setAuthorized(true);
  }

  return (
    <div className={classes.wrapper}>
      <BrowserRouter>
        <AppContainer>
          <>
            {authorized ? (
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/shipments" element={<Shipments />} />
                <Route path="/stock" element={<Stock />} />
                <Route path="/shipments/detail/:id" element={<ShipmentDetail />} />
                <Route path="/stock/category/:id" element={<Category />} />
                <Route path="/stock/productgroup/:id" element={<ProductGroup />} />
                <Route path="/stock/search/:searchText" element={<ProductSearch />} />
                <Route path="/listing" element={<Overview />} />
                <Route path="/listing/:id" element={<Listing />} />
                <Route path="/listing/create" element={<CreateListing />} />
                <Route path="/listing/edit/:id" element={<EditListing />} />
                <Route path="/redirect" element={<LoggedIn />} />
                <Route path="/logout" element={<Navigate to="/" />}></Route>
              </Routes>
            ) : null}
          </>
        </AppContainer>
      </BrowserRouter>
    </div>
  );
};

export default connect()(App);
