import moment from "moment";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, Box, Button, MediaQuery, Skeleton, Table, Text } from "@mantine/core";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import { RootState } from "../../../app/store";
import { ShipmentStatus } from "../types";
import { searchShipments } from "../api/shipmentAsyncActions";
import ShipmentTableControls from "./ShipmentTableControls";

const ShipmentTable = () => {
  const dispatch = useAppDispatch();
  const shipmentOptions = useSelector((state: RootState) => state.shipmentSearch.searchParams);
  const data = useAppSelector((state) => state.shipmentSearch);

  useEffect(() => {
    dispatch(searchShipments(shipmentOptions));
  }, [dispatch, shipmentOptions]);

  if (data === undefined || data.loading) {
    return <ShipmentsLoading />;
  }

  if (data.shipments.length === 0) {
    return (
      <>
        <Box
          py={35}
          px={10}
          sx={{
            display: "flex",
            alignContent: "center",
            textAlign: "center",
            minHeight: 300,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <Text mb={2} size="lg" weight={600}>
              No results found
            </Text>
            <Text color="dimmed">Try adjusting your search or filter to find what you're looking for.</Text>
          </Box>
        </Box>
      </>
    );
  }

  function renderBadge(status: ShipmentStatus) {
    switch (status) {
      case ShipmentStatus.Printed:
        return (
          <Badge radius="sm" color="primary" variant="outline">
            Label Printed
          </Badge>
        );
      case ShipmentStatus.Manifested:
        return (
          <Badge radius="sm" color="green" variant="outline">
            Manifested
          </Badge>
        );
      default:
        return (
          <Badge radius="sm" color="orange" variant="outline">
            Pending
          </Badge>
        );
    }
  }

  return (
    <>
      <Box mt={15}>
        <ShipmentTableControls />
      </Box>
      <Box mt={15}>
        <Table horizontalSpacing="sm" verticalSpacing="xs" fontSize="sm">
          <thead>
            <tr>
              <th>No.</th>
              <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                <th>Date</th>
              </MediaQuery>
              <th>Customer</th>
              <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                <th style={{ textAlign: "right" }}>Status</th>
              </MediaQuery>

              <th style={{ textAlign: "right" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.shipments.map((row, index) => (
              <tr key={row.shipmentId}>
                <td>
                  <Text size="sm" weight={600}>
                    {row.orderRef}
                  </Text>
                </td>
                <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                  <td>{moment(row.shipmentDate).format("LLL")}</td>
                </MediaQuery>
                <td>
                  <Text size="sm" weight={600} color="theme">
                    {row.address.customerName}
                    {row.address.company && ` - ${row.address.company}`}
                  </Text>
                  <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                    <Text size="xs" color="dimmed">
                      {row.address.street1}, {row.address.postcode}
                    </Text>
                  </MediaQuery>
                </td>
                <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                  <td align="right">{renderBadge(row.shipmentStatus)}</td>
                </MediaQuery>

                <td align="right">
                  <Button component={Link} to={`/shipments/detail/${row.shipmentId}`} size="xs">
                    Details
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
      <Box mt={15}>
        <ShipmentTableControls />
      </Box>
    </>
  );
};

const ShipmentsLoading = () => {
  return (
    <Table horizontalSpacing={5} verticalSpacing="xs" fontSize="sm">
      <thead>
        <tr>
          <th>No.</th>
          <th>Date</th>
          <th>Customer</th>
          <th>Type</th>
          <th>Status</th>
          <th align="right">Action</th>
        </tr>
      </thead>
      <tbody>
        {Array(15)
          .fill(1)
          .map((el, i) => {
            return (
              <tr key={i}>
                <td width={60}>
                  <Skeleton height={27} />
                </td>
                <td width={120}>
                  <Skeleton height={27} />
                </td>
                <td width={150}>
                  <Skeleton height={27} />
                </td>
                <td width={40}>
                  <Skeleton height={27} />
                </td>
                <td width={150}>
                  <Skeleton height={27} />
                </td>
                <td align="right" width={70}>
                  <Skeleton height={27} />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default ShipmentTable;
