import { Autocomplete } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchProductsQuery } from "../api/stockApi-slice";

interface Props {
  initialText?: string;
}

const SearchBox = (props: Props) => {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState(props.initialText || "");
  const [debouncedSearchTerm] = useDebouncedValue(searchText, 200);
  const { data } = useSearchProductsQuery(debouncedSearchTerm, { skip: debouncedSearchTerm === "" });

  const handleSubmit = () => {
    if (searchText === "") return;

    navigate(`/stock/search/${encodeURIComponent(searchText)}`);
  };

  return (
    <>
      <Autocomplete
        size="sm"
        label="Search for a product"
        value={searchText}
        radius="xs"
        transitionDuration={80}
        transitionTimingFunction="ease"
        onChange={(value) => setSearchText(value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSubmit();
          }
        }}
        data={data?.map((product) => product.name) || []}
        onItemSubmit={(item) => {
          setSearchText(item.value);
          handleSubmit();
        }}
        mb="sm"
      />
    </>
  );
};

export default SearchBox;
