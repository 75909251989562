import { Box, Skeleton, Text, Paper, Center } from "@mantine/core";

interface Props {
  count: number;
  isLoading: boolean;
}
const ShipmentsThisWeekCard = ({ count, isLoading }: Props) => {
  return (
    <Paper p="md" shadow="sm" sx={{ textAlign: "center", height: 140 }}>
      <Skeleton visible={isLoading} height="100%">
        <Center sx={{ height: "100%" }}>
          <Box>
            <Text weight={700} size="lg">
              {count}
            </Text>
            <Text color="dimmed" size="sm">
              This Week
            </Text>
          </Box>
        </Center>
      </Skeleton>
    </Paper>
  );
};

export default ShipmentsThisWeekCard;
