import { ShipmentTracking } from "../types";
import moment from "moment";
import { Skeleton, Timeline, Text, ThemeIcon, Stack } from "@mantine/core";
import { Check } from "tabler-icons-react";

interface Props {
  data?: ShipmentTracking;
  loading: boolean;
}
const TrackingHistory = ({ data, loading }: Props) => {
  if (loading) {
    return (
      <Stack mt={15}>
        <Skeleton height={80} />
        <Skeleton height={80} />
        <Skeleton height={80} />
        <Skeleton height={80} />
      </Stack>
    );
  }

  if (!data) {
    return (
      <Text size="sm" mt={15}>
        No tracking available, please try again later.
      </Text>
    );
  }

  return (
    <Timeline mt={25} bulletSize={24} lineWidth={2}>
      {data.history.map((history, index) => (
        <Timeline.Item
          pl={40}
          key={index}
          title={`${history.status}`}
          bullet={
            history.status === "Delivered by" ? (
              <ThemeIcon radius="xl">
                <Check color="white" size={14} />
              </ThemeIcon>
            ) : null
          }
        >
          <Text color="dimmed" size="sm">
            {history.status}, {history.location}
          </Text>
          <Text size="xs" mt={4}>
            {moment(history.updatedTime).format("LLL")}
          </Text>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default TrackingHistory;
