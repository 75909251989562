import { Anchor, Grid, Paper, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import PageLoading from "../../../components/Elements/PageLoading";
import { HoseGroup } from "../types";

interface Props {
  hoseGroups: HoseGroup[];
  isLoading: boolean;
}
const ProductGroupList = ({ hoseGroups, isLoading }: Props) => {
  if (isLoading) return <PageLoading />;

  if (!hoseGroups) return null;
  return (
    <>
      <Grid mt="md">
        {hoseGroups.map((product, index) => (
          <Grid.Col key={index} xs={6} sm={4} md={3}>
            <Anchor component={Link} to={`/listing/${product.id}`} style={{ textDecoration: "none" }}>
              <Paper shadow="sm" p="md" sx={{ height: "100%" }}>
                <Text size="sm" weight={600}>
                  {product.description}
                </Text>
                <Text mt={5} color="dimmed" size="xs">
                  {product.skuPrefix}
                </Text>
              </Paper>
            </Anchor>
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
};

export default ProductGroupList;
