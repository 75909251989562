import { ReactElement } from "react";
import ProductGroupListItem from "./ProductGroupListItem";
import { useGetProductGroupsByCategoryIdQuery } from "../api/stockApi-slice";
import { Grid } from "@mantine/core";

interface Props {
  id: string;
}

const ProductGroupList = (props: Props): ReactElement => {
  const { data } = useGetProductGroupsByCategoryIdQuery(props.id);

  return (
    <>
      <Grid>
        {data?.map((item, index) => (
          <Grid.Col key={index} sm={6} lg={4} xl={3}>
            <ProductGroupListItem id={item.productGroupId} name={item.name} imageUrl={item.imageURL}></ProductGroupListItem>
          </Grid.Col>
        ))}
      </Grid>
    </>
  );
};

export default ProductGroupList;
