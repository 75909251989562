import { ReactElement } from "react";
import PageTitle from "../../../components/Elements/PageTitle";
import CategoryItem from "../components/CategoryItem";
import SearchBox from "../components/SearchBox";
import { useGetRootCategoriesQuery } from "../api/stockApi-slice";
import Masonry from "react-masonry-css";
import "./Stock.css";
import PageLoading from "../../../components/Elements/PageLoading";

const Stock = (): ReactElement => {
  const { data, isLoading } = useGetRootCategoriesQuery();

  return (
    <>
      <PageTitle title="Stock" area="Overview" />
      <SearchBox />
      {isLoading ? (
        <PageLoading />
      ) : (
        <Masonry
          breakpointCols={{
            default: 4,
            1300: 3,
            1000: 2,
            800: 1,
          }}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column"
        >
          {data?.map((category) => (
            <CategoryItem key={category.categoryId} category={category} />
          ))}
        </Masonry>
      )}
    </>
  );
};

export default Stock;
