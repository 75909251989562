import { Box, Button, Group, NumberInput, Paper } from "@mantine/core";
import { useForm } from "@mantine/hooks";
import { ArrowsDiagonal2, Calculator } from "tabler-icons-react";
import { useAppDispatch } from "../../../app/hook";
import { addLength } from "../draftListing-slice";

const AddLengthForm = () => {
  const dispatch = useAppDispatch();

  const form = useForm({
    initialValues: {
      length: 1,
      costMultiplier: 1,
    },
  });

  const handleSubmit = ({ length, costMultiplier }: { length: number; costMultiplier: number }) => {
    dispatch(addLength({ id: 0, length, costMultiplier }));
  };

  return (
    <Paper shadow="xs" p="lg">
      <Box component="form" onSubmit={form.onSubmit(handleSubmit)}>
        <NumberInput
          required
          id="lenth"
          name="lenth"
          label="Length (metres)"
          description="The length of the hose"
          precision={2}
          icon={<ArrowsDiagonal2 size={18} />}
          {...form.getInputProps("length")}
        />

        <NumberInput
          required
          mt="lg"
          id="costMultiplier"
          name="costMultiplier"
          label="Cost Multiplier"
          description="The cost multiplier for this length of hose"
          precision={2}
          icon={<Calculator size={18} />}
          {...form.getInputProps("costMultiplier")}
        />

        <Group position="right" mt="lg">
          <Button type="submit">Add Diameter</Button>
        </Group>
      </Box>
    </Paper>
  );
};

export default AddLengthForm;
