import axios from "axios";
import { protectedResources } from "./authConfig";
import authService from "./AuthService";

export const shippingApi = axios.create({
  baseURL: "https://shipmasterapi.azurewebsites.net/api/shipment/",
  headers: {
    "Content-Type": "application/json",
  },
});

export const listingApi = axios.create({
  baseURL: "https://stockmaster-api.azurewebsites.net/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

shippingApi.interceptors.request.use(async function (response) {
  let token = await authService.refreshAccessToken(protectedResources.shipmasterApi.scopes);

  if (!response || !response.headers) response.headers = {};

  if (token) {
    response.headers["authorization"] = `Bearer ${token}`;
  }

  return response;
});

listingApi.interceptors.request.use(async function (response) {
  let token = await authService.refreshAccessToken(protectedResources.stockmasterApi.scopes);

  if (!response || !response.headers) response.headers = {};

  if (token) {
    response.headers["authorization"] = `Bearer ${token}`;
  }

  return response;
});
