import { Anchor, Paper, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { ProductCategory } from "../types";

const CategoryItem = ({ category }: { category: ProductCategory }) => {
  return (
    <Paper shadow="xs" p="lg" key={category.categoryId}>
      <Anchor component={Link} to={`/stock/category/${category.categoryId}`}>
        <Text mb="xs" size="lg" weight={700} sx={{ color: "primary.main" }}>
          {category.name}
        </Text>
      </Anchor>
      {category.childCategories.map((subCategory) => (
        <Anchor
          component={Link}
          key={subCategory.categoryId}
          to={`/stock/category/${subCategory.categoryId}`}
          py={2}
          sx={(theme) => ({
            paddingLeft: 20,
            display: "block",
            borderLeft: "2px solid #EEEEEE",
            color: theme.colors.gray[9],
            "&:hover": {
              color: theme.colors.brand[6],
              borderLeftColor: theme.colors.brand[6],
            },
          })}
          style={{ textDecoration: "none" }}
        >
          <Text size="sm" color="inherit">
            {subCategory.name}
          </Text>
        </Anchor>
      ))}
    </Paper>
  );
};

export default CategoryItem;
