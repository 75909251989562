import { useParams } from "react-router-dom";
import PageTitle from "../../../components/Elements/PageTitle";
import SearchBox from "../components/SearchBox";
import { useSearchProductsQuery } from "../api/stockApi-slice";
import ProductGroupListItem from "../components/ProductGroupListItem";
import { Grid } from "@mantine/core";
import PageLoading from "../../../components/Elements/PageLoading";

const ProductSearch = () => {
  const { searchText } = useParams();
  const { data, isLoading, isFetching } = useSearchProductsQuery(searchText || "");

  return (
    <>
      <PageTitle title="Search Results" area="Product Search" />
      <SearchBox initialText={searchText} />
      {isLoading || isFetching ? (
        <PageLoading />
      ) : (
        <Grid>
          {data?.map((result, index) => (
            <Grid.Col key={index} xs={3}>
              <ProductGroupListItem id={result.productGroupId} name={result.name} imageUrl={result.imageURL} />
            </Grid.Col>
          ))}
        </Grid>
      )}
    </>
  );
};

export default ProductSearch;
