import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DiameterOption, HoseDraft, HoseListing, HoseOption, LengthOption } from "./types";

interface DraftListingState {
  draft: HoseDraft;
  loading: boolean;
}

const initialState: DraftListingState = {
  draft: {
    hoseOptions: [],
    diameters: [],
    lengths: [],
    description: "",
    skuPrefix: "",
  },
  loading: false,
};

const draftListingSlice = createSlice({
  name: "draftListing",
  initialState,
  reducers: {
    generateOptions(state) {
      // remove hoseoptions from state if diameter or length not in state
      state.draft.hoseOptions = filterHoseOptions(state.draft, state.draft.diameters, state.draft.lengths);

      state.draft.diameters.forEach((diameter) => {
        state.draft.lengths.forEach((length) => {
          // Does option exist
          const existingOption = state.draft.hoseOptions.find(
            (hoseOption) => hoseOption.innerDiameter.innerDiameter === diameter.innerDiameter && hoseOption.length.length === length.length
          );

          if (!existingOption) {
            // Add option
            state.draft.hoseOptions.push(createHoseOption(state.draft, diameter, length));
          } else {
            existingOption.description = `${diameter.innerDiameter}mm ${state.draft.description} - ${length.length} ${
              length.length === 1 ? "Metre" : "Metres"
            }`;
            existingOption.sku = `${state.draft.skuPrefix}-${diameter.innerDiameter}-${length.length}`;
            existingOption.calculatedPrice = diameter.costPerMetre * length.length * diameter.costMultiplier * length.costMultiplier;
            existingOption.calculatedCost = diameter.costPerMetre * length.length;
            existingOption.calculatedWeight = diameter.weightPerMetre * length.length;
          }
        });
      });

      state.draft.hoseOptions = sortOptions(state.draft.hoseOptions);
    },
    setDescription(state, action: PayloadAction<string>) {
      state.draft.description = action.payload;
    },
    setSkuPrefix(state, action: PayloadAction<string>) {
      state.draft.skuPrefix = action.payload;
    },
    updateDiameters(state, action: PayloadAction<DiameterOption[]>) {
      // order by diameter
      state.draft.diameters = [...action.payload].sort((a, b) => a.innerDiameter - b.innerDiameter);
    },
    addDiameter(state, action: PayloadAction<DiameterOption>) {
      // add diameter if not already in state
      if (!state.draft.diameters.find((diameter) => diameter.innerDiameter === action.payload.innerDiameter)) {
        state.draft.diameters = [...state.draft.diameters, action.payload].sort((a, b) => a.innerDiameter - b.innerDiameter);
      }
    },
    updateLengths(state, action: PayloadAction<LengthOption[]>) {
      // order by length
      state.draft.lengths = [...action.payload].sort((a, b) => a.length - b.length);
    },
    addLength(state, action: PayloadAction<LengthOption>) {
      if (!state.draft.lengths.find((length) => length.length === action.payload.length)) {
        state.draft.lengths = [...state.draft.lengths, action.payload].sort((a, b) => a.length - b.length);
      }
    },
    removeDiameter(state, action: PayloadAction<DiameterOption>) {
      state.draft.diameters = state.draft.diameters.filter(
        (diameterOption) => diameterOption.innerDiameter !== action.payload.innerDiameter
      );
    },
    removeLength(state, action: PayloadAction<LengthOption>) {
      state.draft.lengths = state.draft.lengths.filter((lengthOption) => lengthOption.length !== action.payload.length);
    },
    toggleEnabled(state, action: PayloadAction<HoseOption>) {
      state.draft.hoseOptions = state.draft.hoseOptions.map((hoseOption) => {
        if (hoseOption.sku === action.payload.sku) {
          return {
            ...hoseOption,
            enabled: !hoseOption.enabled,
          };
        }
        return hoseOption;
      });
    },
    setHoseOptions(state, action: PayloadAction<HoseListing[]>) {
      state.draft.hoseOptions = action.payload.map((listing: HoseListing) => {
        return {
          id: listing.id,
          description: listing.name,
          sku: listing.sku,
          innerDiameter: state.draft.diameters.find((diameter: DiameterOption) => diameter.innerDiameter === listing.innerDiameter)!,
          length: state.draft.lengths.find((length: LengthOption) => length.length === listing.length)!,
          calculatedPrice: listing.price,
          calculatedCost: listing.cost,
          calculatedWeight: listing.weight,
          enabled: listing.enabled,
        };
      });
    },
    resetState: () => initialState,
  },
});

export const {
  generateOptions,
  setDescription,
  setSkuPrefix,
  addDiameter,
  addLength,
  updateDiameters,
  updateLengths,
  removeDiameter,
  removeLength,
  toggleEnabled,
  setHoseOptions,
  resetState,
} = draftListingSlice.actions;
export default draftListingSlice.reducer;

// Helper functions
const filterHoseOptions = (draft: HoseDraft, diamters: DiameterOption[], lengths: LengthOption[]) => {
  return draft.hoseOptions.filter(
    (hoseOption) =>
      diamters.find((diameter) => diameter.innerDiameter === hoseOption.innerDiameter.innerDiameter) &&
      lengths.find((length) => length.length === hoseOption.length.length)
  );
};

const createHoseOption = (draft: HoseDraft, diameter: DiameterOption, length: LengthOption) => {
  return {
    id: 0,
    innerDiameter: diameter,
    length: length,
    description: `${diameter.innerDiameter}mm ${draft.description} - ${length.length} ${length.length === 1 ? "Metre" : "Metres"}`,
    sku: `${draft.skuPrefix}-${diameter.innerDiameter}-${length.length}`,
    calculatedPrice: diameter.costPerMetre * length.length * diameter.costMultiplier * length.costMultiplier,
    calculatedCost: diameter.costPerMetre * length.length,
    calculatedWeight: diameter.weightPerMetre * length.length,
    enabled: true,
  };
};

const sortOptions = (options: HoseOption[]) => {
  // order by diameter then length
  return options.sort((a, b) => {
    if (a.innerDiameter.innerDiameter < b.innerDiameter.innerDiameter) return -1;
    if (a.innerDiameter.innerDiameter > b.innerDiameter.innerDiameter) return 1;
    if (a.length.length < b.length.length) return -1;
    if (a.length.length > b.length.length) return 1;
    return 0;
  });
};
