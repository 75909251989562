import React, { useState } from "react";
import {
  AppShell,
  Container,
  Burger,
  Group,
  Header,
  MediaQuery,
  Navbar,
  Text,
  ThemeIcon,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { BoxModel, LayoutDashboard, Plus, TruckDelivery } from "tabler-icons-react";
import { NavLink } from "react-router-dom";
import User from "./User";

const linksData = [
  { to: "/", label: "Dashboard", icon: <LayoutDashboard size={16} />, color: "blue" },
  { to: "/shipments", label: "Shipments", icon: <TruckDelivery size={16} />, color: "teal" },
  { to: "/stock", label: "Stock", icon: <BoxModel size={16} />, color: "violet" },
  { to: "/listing", label: "Hose Listings", icon: <Plus size={16} />, color: "grape" },
];

interface Props {
  children: React.ReactNode;
}

export const AppContainer = ({ children }: Props) => {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      fixed
      navbarOffsetBreakpoint="md"
      header={
        <Header fixed height={70} p="md">
          <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <Burger opened={opened} onClick={() => setOpened((o) => !o)} size="sm" color={theme.colors.gray[6]} mr="xl" />
            </MediaQuery>

            <Text size="lg" mx="sm" weight={700} align="center">
              Shipmaster
            </Text>
          </div>
        </Header>
      }
      navbar={
        <Navbar hidden={!opened} hiddenBreakpoint="md" p="xs" fixed width={{ base: 300 }}>
          <Navbar.Section grow mt="md">
            {linksData.map((link, index) => (
              <UnstyledButton
                component={NavLink}
                key={index}
                to={link.to}
                sx={(theme) => ({
                  display: "block",
                  padding: theme.spacing.xs,
                  borderRadius: theme.radius.sm,
                  "&:hover": {
                    backgroundColor: theme.colors.gray[0],
                    textDecoration: "none",
                  },
                  "&.active": {
                    backgroundColor: theme.colors.gray[0],
                    fontWeight: 700,
                  },
                })}
              >
                <Group>
                  <ThemeIcon color={link.color} variant="light">
                    {link.icon}
                  </ThemeIcon>
                  <Text size="sm">{link.label}</Text>
                </Group>
              </UnstyledButton>
            ))}
          </Navbar.Section>
          <Navbar.Section>
            <User />
          </Navbar.Section>
        </Navbar>
      }
    >
      <Container fluid>{children}</Container>
    </AppShell>
  );
};
