import { createSlice } from "@reduxjs/toolkit";
import {
  getShipmentCountByDate,
  getShipmentCountByWeek,
  getShipmentTotalCount,
  getShipmentSummary,
} from "../shipments/api/shipmentAsyncActions";
import { ShipmentSummary } from "../shipments/types";

interface DashboardState {
  allTimeShipments: {
    count: number;
    loading: boolean;
  };
  shipmentsToday: {
    count: number;
    loading: boolean;
  };
  shipmentsThisWeek: {
    count: number;
    loading: boolean;
  };
  shipmentSummary: {
    data?: ShipmentSummary;
    loading: boolean;
  };
}

const initialState: DashboardState = {
  allTimeShipments: {
    count: 0,
    loading: false,
  },
  shipmentsToday: {
    count: 0,
    loading: false,
  },
  shipmentsThisWeek: {
    count: 0,
    loading: false,
  },
  shipmentSummary: {
    data: undefined,
    loading: false,
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getShipmentCountByDate.pending, (state, action) => {
      state.shipmentsToday.loading = true;
    });
    builder.addCase(getShipmentCountByDate.fulfilled, (state, action) => {
      state.shipmentsToday.count = action.payload;
      state.shipmentsToday.loading = false;
    });
    builder.addCase(getShipmentCountByDate.rejected, (state, action) => {
      state.shipmentsToday.loading = false;
    });
    builder.addCase(getShipmentCountByWeek.pending, (state, action) => {
      state.shipmentsThisWeek.loading = true;
    });
    builder.addCase(getShipmentCountByWeek.fulfilled, (state, action) => {
      state.shipmentsThisWeek.count = action.payload;
      state.shipmentsThisWeek.loading = false;
    });
    builder.addCase(getShipmentCountByWeek.rejected, (state, action) => {
      state.shipmentsThisWeek.loading = false;
    });
    builder.addCase(getShipmentTotalCount.pending, (state, action) => {
      state.allTimeShipments.loading = true;
    });
    builder.addCase(getShipmentTotalCount.fulfilled, (state, action) => {
      state.allTimeShipments.count = action.payload;
      state.allTimeShipments.loading = false;
    });
    builder.addCase(getShipmentTotalCount.rejected, (state, action) => {
      state.allTimeShipments.loading = false;
    });
    builder.addCase(getShipmentSummary.pending, (state, action) => {
      state.shipmentSummary.loading = true;
    });
    builder.addCase(getShipmentSummary.fulfilled, (state, action) => {
      state.shipmentSummary.data = action.payload;
      state.shipmentSummary.loading = false;
    });
    builder.addCase(getShipmentSummary.rejected, (state, action) => {
      state.shipmentSummary.loading = false;
    });
  },
});

export default dashboardSlice.reducer;
