import { useMantineTheme } from "@mantine/core";
import { DateRangePicker } from "@mantine/dates";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/hook";
import { RootState } from "../../../app/store";
import { updateDateRange } from "../shipment-slice";

const ShipmentDatePicker = () => {
  const dispatch = useAppDispatch();
  const shipmentOptions = useSelector((state: RootState) => state.shipmentSearch.searchParams);
  const theme = useMantineTheme();

  return (
    <DateRangePicker
      required
      label="Date Range"
      allowSingleDateInRange
      sx={{ width: 250 }}
      placeholder={"Pick dates range"}
      value={[new Date(shipmentOptions.dateFrom), new Date(shipmentOptions.dateTo)]}
      dayStyle={(date) => {
        if (date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth())
          return { border: `1px dashed ${theme.colors.brand[5]}` };
        return {};
      }}
      onChange={(date) => {
        if (date !== null && date[0] !== null && date[1] !== null) {
          dispatch(updateDateRange({ dateFrom: date[0].toISOString(), dateTo: date[1].toISOString() }));
        }
      }}
    />
  );
};

export default ShipmentDatePicker;
