import React, { ReactElement } from "react";
import PageTitle from "../../../components/Elements/PageTitle";
import ShipmentTable from "../components/ShipmentTable";

import { Box } from "@mantine/core";
import SearchOptions from "../components/SearchOptions";

const Shipments = (): ReactElement => {
  return (
    <div>
      <PageTitle area="Search" title="Shipments" />
      <Box mt={15} sx={{ gap: 1, display: "flex", flexWrap: "wrap", alignItems: "flex-end" }}>
        <SearchOptions />
      </Box>
      <Box>
        <ShipmentTable />
      </Box>
    </div>
  );
};

export default Shipments;
