import { Configuration } from "@azure/msal-browser";

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

export const protectedResources = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  shipmasterApi: {
    endpoint: "https://shipmasterapi.azurewebsites.net/api",
    scopes: ["api://056ee313-4336-4731-ad04-ac63a52c8bd2/access_as_user"],
  },
  stockmasterApi: {
    endpoint: "https://stockmaster-api.azurewebsites.net",
    scopes: ["api://75631195-dae4-4a57-9c91-989e9fd58a70/access_as_user"],
  },
};

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
    authority: process.env.REACT_APP_MSAL_AUTHORITY as string,
    redirectUri: `${window.location.origin}/redirect/`,
    postLogoutRedirectUri: `${window.location.origin}/logout/`,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};
