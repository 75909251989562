import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

class AuthService {
  msalConfig: Configuration;
  msalInstance: PublicClientApplication;

  constructor() {
    this.msalConfig = msalConfig;
    this.msalInstance = new PublicClientApplication(this.msalConfig);
  }

  async refreshAccessToken(scopes: string[]) {
    const account = this.msalInstance.getAllAccounts()[0];

    const request = {
      scopes: [...scopes],
      account: account,
    };

    let response = await this.msalInstance.acquireTokenSilent(request);
    return response.accessToken;
  }
}

const authService = new AuthService();

export default authService;
