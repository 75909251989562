import { Box, Paper, Switch, Table } from "@mantine/core";
import NumberFormat from "react-number-format";
import { HoseOption } from "../types";

interface Props {
  hoseOptions: HoseOption[];
  toggleEnabled: (id: HoseOption) => void;
}
const HoseOptionsTable = ({ hoseOptions, toggleEnabled }: Props) => {
  return (
    <Paper shadow="xs" mt="lg">
      <Table horizontalSpacing="xl" verticalSpacing="md">
        <thead>
          <tr>
            <th>Enabled</th>
            <th>SKU</th>
            <th>Description</th>
            <th style={{ textAlign: "right" }}>Inner Diameter</th>
            <th style={{ textAlign: "right" }}>Length</th>
            <th style={{ textAlign: "right" }}>Weight</th>
            <th style={{ textAlign: "right" }}>Cost</th>
            <th style={{ textAlign: "right" }}>Sell Price</th>
          </tr>
        </thead>
        <tbody>
          {hoseOptions.map((hoseOption: HoseOption) => {
            return (
              <Box
                component="tr"
                key={hoseOption.sku}
                sx={(theme) => ({ color: hoseOption.enabled ? theme.colors.black : theme.colors.gray[5] })}
              >
                <td>
                  <Switch size="md" radius="xs" checked={hoseOption.enabled} onChange={() => toggleEnabled(hoseOption)} />
                </td>
                <td>{hoseOption.sku}</td>
                <td>{hoseOption.description}</td>
                <td align="right">{hoseOption.innerDiameter.innerDiameter}mm</td>
                <td align="right">
                  {hoseOption.length.length} {hoseOption.length.length === 1 ? "Metre" : "Metres"}
                </td>
                <td align="right">
                  <NumberFormat
                    value={hoseOption.calculatedWeight || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    suffix={"kg"}
                    allowLeadingZeros
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </td>
                <td align="right">
                  <NumberFormat
                    value={hoseOption.calculatedCost}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                    allowLeadingZeros
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </td>
                <td align="right">
                  <NumberFormat
                    value={hoseOption.calculatedPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"£"}
                    allowLeadingZeros
                    fixedDecimalScale
                    decimalScale={2}
                  />
                </td>
              </Box>
            );
          })}
        </tbody>
      </Table>
    </Paper>
  );
};

export default HoseOptionsTable;
