export interface Shipment {
  shipmentId: number;
  orderRef: string;
  trackingNumber: string;
  shipmentDate: Date;
  address: ShipmentAddress;
  orderType: OrderType;
  shipmentStatus: ShipmentStatus;
  courierService: CourierService;
  tracking: ShipmentTracking;
}

export interface CourierService {
  courier: Courier;
  serviceName: string;
}

export interface Courier {
  courierId: number;
  courierType: string;
  name: string;
}

export interface ShipmentAddress {
  addressId: number;
  customerName: string;
  company: string;
  street1: string;
  street2: string;
  street3: string;
  city: string;
  postcode: string;
  country: Country;
}

export interface ShipmentTracking {
  trackingNumber: string;
  history: ShipmentTrackingHistory[];
}

export interface ShipmentTrackingHistory {
  status: string;
  location: string;
  updatedTime: Date;
}

export enum ShipmentStatus {
  "Scanned" = 0,
  "Allocated" = 1,
  "Printed" = 2,
  "Manifested" = 3,
  "Cancelled" = 4,
}

export enum ParcelTypes {
  "Letter" = 0,
  "Large Letter" = 1,
  "Parcel" = 2,
}

export interface OrderType {
  orderTypeId: number;
  codeRef: string;
}

export interface Country {
  countryId: number;
  countryName: string;
}

export interface ShipmentSearchParams {
  dateFrom: string;
  dateTo: string;
  filterBy: string;
  filterText: string;
  orderBy: string;
  page: number;
  itemsPerPage: number;
}

export interface ShipmentSearchResult {
  shipments: Shipment[];
  totalItemCount: number;
}

export interface ShipmentSummary {
  parcelCountSummary: ParcelCountSummary[];
  parcelTypes: ParcelType[];
  serviceCountSummary: ServiceCountSummary[];
  totalShipments: number;
  totalShipmentsPreviousPeriod: number;
}
export interface ParcelCountSummary {
  parcelType: number;
  count: number;
}

export interface ServiceCountSummary {
  serviceName: string;
  count: number;
}

export interface ParcelType {
  date: string;
  largeLetters: number;
  parcels: number;
  letters: number;
  total: number;
}
