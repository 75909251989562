import { Box, Text, Skeleton } from "@mantine/core";
import React from "react";

interface Props {
  area: string;
  title: string;
}

const PageTitle = (props: Props) => {
  return (
    <Box mb={15}>
      <Text color="dimmed" size="sm">
        {props.area}
      </Text>
      <Text weight={700} size="xl">
        {props.title}
      </Text>
    </Box>
  );
};

export const PageTitleSkeleton = () => {
  return (
    <Box mb={15}>
      <Skeleton width={110} height={20} sx={{ marginBottom: 3 }} />
      <Skeleton width={180} height={30} />
    </Box>
  );
};

export default PageTitle;
