import * as ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import App from "./app/App";
import { store, history } from "./app/store";
import { MsalProvider, MsalAuthenticationTemplate, UnauthenticatedTemplate, AuthenticatedTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { protectedResources } from "./authConfig";

import authService from "./AuthService";
import { MantineProvider } from "@mantine/core";
import { LoadingPage } from "./components/LoadingPage";
import { PublicHomePage } from "./components/PublicHomePage";

ReactDOM.render(
  <MantineProvider
    theme={{
      colors: {
        brand: ["#FFF5F5", "#FFE3E3", "#FFC9C9", "#FFA8A8", "#FF8787", "#FF6B6B", "#FA5252", "#F03E3E", "#E03131", "#C92A2A"],
      },
      fontSizes: {
        xs: 12,
        sm: 14,
        md: 16,
        lg: 18,
        xl: 20,
      },
      // set primary color
      primaryColor: "brand",
    }}
  >
    <MsalProvider instance={authService.msalInstance}>
      <UnauthenticatedTemplate>
        <PublicHomePage />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Popup}
          authenticationRequest={protectedResources.shipmasterApi}
          loadingComponent={LoadingPage}
        >
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <App />
            </ConnectedRouter>
          </Provider>
        </MsalAuthenticationTemplate>
      </AuthenticatedTemplate>
    </MsalProvider>
  </MantineProvider>,
  document.getElementById("root")
);
