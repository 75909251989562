import { createAsyncThunk } from "@reduxjs/toolkit";
import { shippingApi } from "../../../apiConfig";
import { Shipment, ShipmentSearchParams, ShipmentSearchResult, ShipmentSummary, ShipmentTracking } from "../types";

export const getShipmentById = createAsyncThunk<Shipment, string>("shipmentApi/getShipmentById", async (id: string) => {
  const response = await shippingApi.get(`${id}`);
  return response.data;
});

export const searchShipments = createAsyncThunk<ShipmentSearchResult, ShipmentSearchParams>(
  "shipmentApi/searchShipments",
  async (params: ShipmentSearchParams) => {
    const response = await shippingApi.get(`search`, { params });
    return response.data;
  }
);

export const getShipmentTotalCount = createAsyncThunk<number>("shipmentApi/getShipmentTotalCount", async () => {
  const response = await shippingApi.get(`count`);
  return response.data;
});

export const getShipmentCountByDays = createAsyncThunk<number, number>("shipmentApi/getShipmentCountByDays", async (count: number) => {
  const response = await shippingApi.get(`count/${count}`);
  return response.data;
});

export const getShipmentCountByDate = createAsyncThunk<number, string>("shipmentApi/getShipmentCountByDate", async (date: string) => {
  const response = await shippingApi.get(`count?date=${date}`);
  return response.data;
});

export const getShipmentCountByWeek = createAsyncThunk<number>("shipmentApi/getShipmentCountByWeek", async () => {
  const response = await shippingApi.get(`week`);
  return response.data;
});

export const getShipmentSummary = createAsyncThunk<ShipmentSummary>("shipmentApi/getShipmentSummary", async () => {
  const response = await shippingApi.get(`parcel/summary`);
  return response.data;
});

export const getShipmentTracking = createAsyncThunk<ShipmentTracking, Shipment>(
  "shipmentApi/getShipmentTracking",
  async (shipment: Shipment) => {
    const response = await shippingApi.get(`/tracking/${shipment.courierService.courier.name}/${shipment.trackingNumber}`);
    return response.data;
  }
);
