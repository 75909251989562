import { HoseListing, HoseOption } from "./types";

export function MapToHoseListings(hoseOptions: HoseOption[]): HoseListing[] {
  return hoseOptions.map((hoseOption: HoseOption) => {
    return {
      id: hoseOption.id ?? 0,
      name: hoseOption.description,
      sku: hoseOption.sku,
      innerDiameter: hoseOption.innerDiameter.innerDiameter,
      length: hoseOption.length.length,
      price: RoundPrice(hoseOption.calculatedPrice),
      cost: RoundPrice(hoseOption.calculatedCost),
      weight: RoundWeight(hoseOption.calculatedWeight),
      enabled: hoseOption.enabled,
    };
  });
}

export function RoundPrice(price: number): number {
  return Math.round(price * 100 + Number.EPSILON) / 100;
}

export function RoundWeight(weight: number): number {
  return Math.round(weight * 100 + Number.EPSILON) / 100;
}
