import { connectRouter, routerMiddleware } from "connected-react-router";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/auth-slice";
import shipmentSearchReducer from "../features/shipments/shipment-slice";
import listingReducer from "../features/listing/listing-slice";
import draftListingReducer from "../features/listing/draftListing-slice";
import dashboardReducer from "../features/dashboard/dashboard-slice";
import stockApiReducer, { stockApi } from "../features/stock/api/stockApi-slice";
import { createBrowserHistory } from "history";

// Create browser history to use in the Redux store
const baseUrl = (document.querySelector("base") || {}).href as string;
export const history = createBrowserHistory({ basename: baseUrl });

const rootReducer = combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  shipmentSearch: shipmentSearchReducer,
  listing: listingReducer,
  draftListing: draftListingReducer,
  dashboard: dashboardReducer,
  [stockApi.reducerPath]: stockApiReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware(), stockApi.middleware, routerMiddleware(history)],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
