import moment from "moment";
import React, { useEffect } from "react";
import { Grid, Paper, Text } from "@mantine/core";
import { connect } from "react-redux";
import PageTitle from "../../../components/Elements/PageTitle";
import ShipmentSummaryCard from "../components/ShipmentSummaryCard";
import ParcelSummaryChart from "../components/ParcelSummaryChart";
import ParcelTypeCard from "../components/ParcelTypeCard";
import ServiceTypeCard from "../components/ServiceTypeCard";
import PreviousPeriodCard from "../components/PreviousPeriodCard";
import AllTimeShipmentsCard from "../components/AllTimeShipmentsCard";
import ShipmentsTodayCard from "../components/ShipmentsTodayCard";
import ShipmentsThisWeekCard from "../components/ShipmentsThisWeekCard";
import { useAppDispatch, useAppSelector } from "../../../app/hook";
import {
  getShipmentCountByDate,
  getShipmentCountByWeek,
  getShipmentSummary,
  getShipmentTotalCount,
} from "../../shipments/api/shipmentAsyncActions";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let date = moment(new Date()).format("YYYY-MM-DD");

    dispatch(getShipmentCountByDate(date));
    dispatch(getShipmentCountByWeek());
    dispatch(getShipmentTotalCount());
    dispatch(getShipmentSummary());
  }, [dispatch]);

  const data = useAppSelector((state) => state.dashboard);

  return (
    <React.Fragment>
      <PageTitle area="Overview" title="Dashboard" />
      <Grid>
        <Grid.Col md={6} lg={3}>
          <ShipmentsTodayCard count={data.shipmentsToday.count} isLoading={data.shipmentsToday.loading} />
        </Grid.Col>

        <Grid.Col md={6} lg={3}>
          <ShipmentsThisWeekCard count={data.shipmentsThisWeek.count} isLoading={data.shipmentsThisWeek.loading} />
        </Grid.Col>

        <Grid.Col md={6} lg={3}>
          <ShipmentSummaryCard data={data.shipmentSummary.data} isLoading={data.shipmentSummary.loading} />
        </Grid.Col>

        <Grid.Col md={6} lg={3}>
          <AllTimeShipmentsCard count={data.allTimeShipments.count} isLoading={data.allTimeShipments.loading} />
        </Grid.Col>

        <Grid.Col xs={12}>
          <Paper p="xl" shadow="sm">
            <Text mb="lg" weight={600}>
              Parcel Summary (Past 30 days)
            </Text>
            <ParcelSummaryChart data={data.shipmentSummary.data} isLoading={data.shipmentSummary.loading} />
          </Paper>
        </Grid.Col>

        <Grid.Col xs={12} md={4}>
          <ParcelTypeCard data={data.shipmentSummary.data} isLoading={data.shipmentSummary.loading} />
        </Grid.Col>

        <Grid.Col xs={12} md={4}>
          <ServiceTypeCard data={data.shipmentSummary.data} isLoading={data.shipmentSummary.loading} />
        </Grid.Col>

        <Grid.Col xs={12} md={4}>
          <PreviousPeriodCard data={data.shipmentSummary.data} isLoading={data.shipmentSummary.loading} />
        </Grid.Col>
      </Grid>
    </React.Fragment>
  );
};

export default connect()(Dashboard);
