import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { protectedResources } from "../../../authConfig";
import authService from "../../../AuthService";
import { ProductCategory, ProductGroup, ProductSearchResult } from "../types";

export const stockApi = createApi({
  reducerPath: "stockApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://stockmaster-api.azurewebsites.net/api/",
    prepareHeaders: async (headers, { getState }) => {
      let token = await authService.refreshAccessToken(protectedResources.stockmasterApi.scopes);
      if (token) headers.set("authorization", `Bearer ${token}`);

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRootCategories: builder.query<ProductCategory[], void>({
      query: () => `category/root`,
    }),
    getCategory: builder.query<ProductCategory, string>({
      query: (id: string) => `category/${id}`,
    }),
    getProductGroupsByCategoryId: builder.query<ProductGroup[], string>({
      query: (id: string) => `productgroup/cat/${id}`,
    }),
    getProductGroupById: builder.query<ProductGroup, string>({
      query: (id: string) => `productgroup/${id}`,
    }),
    SearchProducts: builder.query<ProductSearchResult[], string>({
      query: (term: string) => `productgroup/search?term=${term}`,
    }),
  }),
});

export const {
  useGetRootCategoriesQuery,
  useGetCategoryQuery,
  useGetProductGroupsByCategoryIdQuery,
  useGetProductGroupByIdQuery,
  useSearchProductsQuery,
} = stockApi;
export default stockApi.reducer;
