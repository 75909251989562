import { useMsal } from "@azure/msal-react";
import { Box, Button, Card, Center, Text, Image } from "@mantine/core";

export const PublicHomePage = () => {
  const { instance, inProgress } = useMsal();

  return (
    <Center sx={{ height: "85%" }}>
      <Card withBorder radius="md" p="xl" sx={{ width: 400 }} shadow="xl">
        <Card.Section>
          <Image src="powerpipes.jpg" height={210} alt="Power Pipes" />
        </Card.Section>
        <Box pt="xl">
          <Text size="xl" weight={600}>
            Shipmaster
          </Text>
          <Text color="dimmed" size="xs">
            by Power Pipes Ltd
          </Text>
        </Box>
        <Text size="sm" py="xl">
          Our online tool for tracking and managing shipments and listings. Click login to continue ..
        </Text>
        <Button size="md" mt="xl" fullWidth onClick={() => instance.loginPopup()} disabled={inProgress === "login"}>
          Login
        </Button>
      </Card>
    </Center>
  );
};
